.spec-head {
  background: #ffda58;
  color: #4c2b11;
  border-bottom: 3px solid #4c2b11;
  @apply text-2xl font-bold rounded-t-xl;
}

.spec-col-1 {
  @apply text-left pl-8 pr-4 font-bold h-20 w-56;
}

.spec-col-rest {
  @apply h-20 w-96 text-center;
}

.spec-line {
  border-bottom: 2px solid #4c2b11;
}

.spec-headline {
  color: #603d00;
  @apply pb-8 font-extrabold;
}
