.mobile-menu-overlay {
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
  transform: translate3d(100%, 0px, 0px);
  @apply fixed z-30 w-full h-full opacity-0;
}

.mobile-menu-slide {
  transition: all 0.5s ease 0s;
  transform: translate3d(-100%, 0px, 0px);

  @apply fixed right-auto z-40 w-4/6 h-full;
}

.is-mobile-menu-open {
  opacity: 1;
  transform: none;
  transition: opacity 0.3s ease 0s;
}

.is-mobile-menu-open-slide {
  transform: none;
}
