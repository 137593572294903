@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.03125rem;
  box-sizing: border-box;
}

body,
button,
input,
select,
table,
textarea {
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 0.5px;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: inherit;
}

body,
button,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

button,
input,
li {
  transition: all 0.2s linear;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

@font-face {
  font-family: 'TmonMonsori';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/TmonMonsori.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.keep-all {
  word-break: keep-all;
}

#root {
  height: 100%;
}
